import React from 'react';
import { Grid, ListItem, Typography } from '@material-ui/core';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const ListHeader = ({ setOrderBy, sortBy, setSortBy }) => {
  const Ordenacao = name => {
    setOrderBy(name);
    if (sortBy === '') setSortBy('asc');
    if (sortBy === 'asc') setSortBy('desc');
    if (sortBy === 'desc') setSortBy('asc');
  };

  return (
    <ListItem>
      <Grid container item>
        <Grid item md={1}>
          <Typography
            align="left"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center'
            }}
            onClick={() => Ordenacao('codigo')}
          >
            Código <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={3}>
          <Typography
            align="left"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center'
            }}
            onClick={() => Ordenacao('descricao')}
          >
            Descrição <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={1}>
          <Typography
            align="left"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center'
            }}
            onClick={() => Ordenacao('ean')}
          >
            Cód. Barra <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography
            align="left"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center'
            }}
            onClick={() => Ordenacao('tabela')}
          >
            Tabela de Pr. <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography
            align="left"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center'
            }}
            onClick={() => Ordenacao('regra')}
          >
            Regra de Des. <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={1}>
          <Typography
            align="left"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center'
            }}
            onClick={() => Ordenacao('precoVenda')}
          >
            Pr. Venda <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography
            align="left"
            style={{
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center'
            }}
            onClick={() => Ordenacao('itemEstoque')}
          >
            Est. Disponível <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={1}>
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer' }}></Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ListHeader;
