import React, { useState } from 'react';
// import { Table, TableBody, TableContainer, TableRow, TableCell } from '@material-ui/core';
import { Grid, ListItem, Typography, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { formatCurrency } from '~/utils/format';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { MoreVert as IconMore } from '@material-ui/icons';
import ModalSaldoEstoqueProduto from '../../modal/ModalSaldoEstoqueProduto';
import ModalCondicaoPagamentoProduto from '../../modal/ModalCondicaoPagamentoProduto';

const ListItemProduto = ({ data, callback }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [produto] = useState(data);
  const [showModalCondicaoPagamento, setShowModalCondicaoPagamento] = useState(false);
  const [showModalSaldoEstoque, setShowModalSaldoEstoque] = useState(false);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let regraDescontoValor = 0;
  let regraDescontoPercentual = 0;
  
  produto?.fat_regradesconto_produto?.forEach(regra => {
    if (regraDescontoValor === 0) {
      regraDescontoValor = regra.descontoValor;
    }
    if (regraDescontoPercentual === 0) {
      regraDescontoPercentual = regra.descontoPercentual;
    }
    if (regraDescontoValor !== 0 && regraDescontoPercentual !== 0) {
      return;
    }
  });
  
  let precoVendaComDesconto;
  if (produto?.fat_regradesconto_produto?.length > 0) {
    if (regraDescontoValor > 0) {
      precoVendaComDesconto = produto?.estProdutoImposto[0]?.precoVenda - regraDescontoValor;
    } else if (regraDescontoPercentual > 0) {
      precoVendaComDesconto = produto?.estProdutoImposto[0]?.precoVenda - (produto?.estProdutoImposto[0]?.precoVenda * (regraDescontoPercentual / 100));
    } else {
      precoVendaComDesconto = produto?.estProdutoImposto[0]?.precoVenda;
    }
  } else {
    precoVendaComDesconto = produto?.estProdutoImposto[0]?.precoVenda;
  }

  const handleProdutoClick = (produtoSelecionado) => {
    callback(produtoSelecionado);
  };

  return (
    <>
      {produto?.fat_regradesconto_produto?.length > 0 &&
        produto?.fat_regradesconto_produto.map((regra, index) => (
          <React.Fragment key={index}>
            <Grid container>
              <ListItem
                button
                disabled={produto?.desativado}
                onClick={(event) => {
                  if (produto?.desativado) {
                    return;
                  }
                  event.stopPropagation();
                  const produtoSelecionado = { ...produto, produtoSelecionadoRegra: regra, tipo: 'regra' };
                  handleProdutoClick(produtoSelecionado);
                }}
              >
                <Grid item md={1}>
                  <Typography>{produto?.codigo}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography>{produto?.descricao}</Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography>{produto?.ean}</Typography>
                </Grid>
                <Grid item md={2} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
                <Grid item md={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{regra.regra_desconto?.descricao}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(precoVendaComDesconto)}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography>{produto?.itemEstoque ?? 0}</Typography>
                </Grid>
                <Grid item md={1}>
                  <ListItemSecondaryAction>
                    <Tooltip title="Menu">
                      <IconButton tabIndex={-1} aria-label={'Menu'} onClick={handleClick}>
                        <IconMore />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowModalSaldoEstoque(true);
                        }}
                      >
                        Saldo de estoque
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowModalCondicaoPagamento(true);
                        }}
                      >
                        Condições de pagamento
                      </MenuItem>
                    </Menu>
                  </ListItemSecondaryAction>
                </Grid>
              </ListItem>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
      {produto?.fatTabelavendaProduto?.length > 0 &&
        produto?.fatTabelavendaProduto.map((tabela, index) => (
          <React.Fragment key={index}>
            <Grid container>
              <ListItem
                button
                disabled={produto?.desativado}
                onClick={(event) => {
                  if (produto?.desativado) {
                    return;
                  }
                  event.stopPropagation();
                  const produtoSelecionado = { ...produto, produtoSelecionadoTabela: tabela, tipo: 'tabela' };
                  handleProdutoClick(produtoSelecionado);
                }}
              >
                <Grid item md={1}>
                  <Typography>{produto?.codigo}</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography>{produto?.descricao}</Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography>{produto?.ean}</Typography>
                </Grid>
                <Grid item md={2} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{tabela.tabela_venda?.descricao}</Typography>
                </Grid>
                <Grid item md={2} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(tabela?.precoPraticado)}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography>{produto?.itemEstoque ?? 0}</Typography>
                </Grid>
                <Grid item md={1}>
                  <ListItemSecondaryAction>
                    <Tooltip title="Menu">
                      <IconButton tabIndex={-1} aria-label={'Menu'} onClick={handleClick}>
                        <IconMore />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowModalSaldoEstoque(true);
                        }}
                      >
                        Saldo de estoque
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          setShowModalCondicaoPagamento(true);
                        }}
                      >
                        Condições de pagamento
                      </MenuItem>
                    </Menu>
                  </ListItemSecondaryAction>
                </Grid>
              </ListItem>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
      {showModalSaldoEstoque && (
        <ModalSaldoEstoqueProduto
          open={showModalSaldoEstoque}
          data={{
            produto: { ...produto },
            saldoEstoque: produto?.saldoEstoque
          }}
          onClose={() => {
            handleClose();
            setShowModalSaldoEstoque(false);
          }}
        />
      )}
      {showModalCondicaoPagamento && (
        <ModalCondicaoPagamentoProduto
          open={showModalCondicaoPagamento}
          data={{
            produto: { ...produto },
            precoVenda: produto?.precoVenda
          }}
          onClose={() => {
            handleClose();
            setShowModalCondicaoPagamento(false);
          }}
        />
      )}
    </>
  );
};

export default ListItemProduto;
