import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { selectorRotina } from '~/store/modules/auth/auth-selectors';

import { SHOW_SHORTCUTS, TIPO_DOCUMENTO } from '~/constants';
import { selectorFechamento } from '~/store/modules/pdv/pdv-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import { ButtonPagamento, Selected } from './styles';
import toast from '~/services/toast';

const CardAtalhoPagamento = () => {
  const dispatch = useDispatch();

  const {
    matrizCondicaoPagamento,
    multiplosPagamentos,
    showModalLoadingMensagem,
    showModalMenuTef,
    itemPagamento,
    tipoDocumento,
    venda
  } = useSelector(state => state.pdv);
  const { valorSaldoPagar, condicaoPagamentoObrigatoria } = useSelector(selectorFechamento([]));
  const rotinaF140DSA = useSelector(selectorRotina('F140DSA'))

  const idsClientePagamento = venda?.intCliente?.clienteMeioPagamento?.map(pagamento => pagamento.finMeiopagamentoId);

  const clienteCondicaoPagamento = matrizCondicaoPagamento?.map(pagamento => {
    if(idsClientePagamento?.includes(pagamento.id) || pagamento.nfePagamento === 5) {
      return {
        ...pagamento,
        enabled: true
      };
    } else {
      return {
        ...pagamento
      }
    }
  });

  let condicoesPagamento = venda?.intCliente?.id ? clienteCondicaoPagamento : matrizCondicaoPagamento;
  if(idsClientePagamento?.length === 0 || !venda?.intCliente || !idsClientePagamento) {
    condicoesPagamento = matrizCondicaoPagamento?.map(pagamento => {
      return {
        ...pagamento,
        enabled: true
      };
    });
  }

  useEffect(() => {
    if(venda?.parcelas?.length > 1) {
      dispatch(pdvActions.setMultiplosPagamentos(true));
    }
  }, [venda?.parcelas]);

  return (
    <Mui.Paper>
      {!condicaoPagamentoObrigatoria && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO && (
        <Mui.Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Mui.Box pt={1}>
            <Mui.Switch
              checked={multiplosPagamentos}
              onChange={async e => {
                if (venda?.parcelas?.length > 0 || venda?.finMeiopagamentol || venda?.fatCondicaopagamento) {
                  const { isConfirmed } = await toast.confirm(
                    'Deseja alterar a forma de efetuar os pagamentos?',
                    'Alterar a forma irá remover os pagamentos anteriores',
                    {
                      confirmButtonText: 'Confirmar',
                      cancelButtonText: 'Cancelar'
                    }
                  );

                  if (isConfirmed) {
                    dispatch(pdvActions.limparPagamentos());
                    dispatch(pdvActions.selecionarMeioPagamento(null));
                    dispatch(pdvActions.selecionarCondicaoPagamento(null));
                    dispatch(pdvActions.setMultiplosPagamentos(!e.target.checked));
                  }
                } else {
                  dispatch(pdvActions.setMultiplosPagamentos(e.target.checked));
                }
              }}
            />
            <Mui.Typography variant="caption">Múltiplos pagamentos</Mui.Typography>
          </Mui.Box>

          <Mui.Box pt={2} pr={2}>
            <Mui.Button
              variant="contained"
              onClick={() => {
                dispatch(pdvActions.setShowModalDesconto({ show: true }));
              }}
              disabled={(tipoDocumento !== TIPO_DOCUMENTO.PEDIDO ? !rotinaF140DSA : false)}
            >
              <MuiIcons.RemoveCircle />
              <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                Desc.
              </Mui.Typography>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              style={{
                marginLeft: 8
              }}
              onClick={() => {
                dispatch(pdvActions.setShowModalAcrescimo({ show: true }));
              }}
              disabled={(tipoDocumento !== TIPO_DOCUMENTO.PEDIDO ? !rotinaF140DSA : false)}
            >
              <MuiIcons.AddCircle />
              <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                Acrésc.
              </Mui.Typography>
            </Mui.Button>
            <Mui.Button
              variant="contained"
              style={{
                marginLeft: 8
              }}
              onClick={() => {
                if (!venda?.parcelas || venda?.parcelas?.length === 0) {
                  toast.warning('Nenhuma parcela informada!');
                  return;
                }
                dispatch(pdvActions.setShowModalListaPagamentos({ show: true }));
              }}
            >
              <Mui.Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                badgeContent={venda?.parcelas?.length === 0 ? null : venda?.parcelas?.length}
                color="error"
              >
                <MuiIcons.AccountBalanceWallet />
              </Mui.Badge>

              <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                Pagamentos {SHOW_SHORTCUTS && '(CTRL+P)'}
              </Mui.Typography>
            </Mui.Button>
          </Mui.Box>
        </Mui.Box>
      )}

      <Mui.Box p={2}>
        <Mui.Grid container item spacing={1}>
          {[...condicoesPagamento.slice(0, 5)].map((meioPagamento, index) => {
            const isSelected = itemPagamento?.meioPagamento?.id === meioPagamento?.id;

            return (
              <Mui.Grid item xs={6} lg={4} key={`index-${index}`}>
                <ButtonPagamento
                  fullWidth
                  variant="outlined"
                  disabled={meioPagamento?.enabled ? false : true}
                  onClick={() => {
                    if (multiplosPagamentos) {
                      if (venda?.itens?.length === 0) {
                        toast.warning('Nenhum item vendido!');
                        return;
                      }
                      if (valorSaldoPagar === 0) {
                        toast.warning('Não existe saldo a pagar!');
                        return;
                      }
                      dispatch(
                        pdvActions.setShowModalMultiploPagamento({
                          show: true,
                          fecharVenda: true,
                          pagamentoSelecionado: {
                            meioPagamento
                          }
                        })
                      );
                    } else {
                      dispatch(
                        pdvActions.setShowModalConsultaPagamento({
                          show: true,
                          fecharVenda: false,
                          pagamentoSelecionado: isSelected
                            ? { ...itemPagamento }
                            : {
                                meioPagamento,
                                condicoes: meioPagamento?.condicoes,
                                condicaoPagamento:
                                  meioPagamento?.condicoes?.length === 1
                                    ? meioPagamento?.condicoes[0]
                                    : null
                              }
                        })
                      );
                    }
                  }}
                >
                  {!showModalLoadingMensagem?.show && <Selected selected={isSelected} />}

                  <Box
                    display="flex"
                    flex={1}
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    py={2}
                  >
                    {showModalLoadingMensagem?.show ? (
                      <Skeleton variant="text" width={180} />
                    ) : (
                      <>
                        <Typography
                          align="center"
                          style={{
                            color: isSelected ? '#33cb8e' : 'unset',
                            fontSize: 14,
                            fontWeight: isSelected ? 600 : 500
                          }}
                        >
                          {meioPagamento?.descricao}{' '}
                          <small>
                            <>{SHOW_SHORTCUTS && `(F${parseInt(5 + index)})`}</>
                          </small>
                        </Typography>
                        {isSelected && (
                          <Typography variant="caption">
                            {itemPagamento?.condicaoPagamento?.descricao}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                </ButtonPagamento>
              </Mui.Grid>
            );
          })}

          <Mui.Grid item xs={6} lg={4}>
            <ButtonPagamento
              fullWidth
              variant="outlined"
              style={{
                fontWeight: 500
              }}
              onClick={() => {
                if (multiplosPagamentos) {
                  if (venda?.itens?.length === 0) {
                    toast.warning('Nenhum item vendido!');
                    return;
                  }
                  if (valorSaldoPagar === 0) {
                    toast.warning('Não existe saldo a pagar!');
                    return;
                  }
                  dispatch(
                    pdvActions.atualizarItemPagamento({
                      finMeiopagamento: null
                    })
                  );
                  dispatch(
                    pdvActions.setShowModalMultiploPagamento({
                      show: true,
                      fecharVenda: true,
                      pagamentoSelecionado: null
                    })
                  );
                } else {
                  dispatch(
                    pdvActions.setShowModalConsultaPagamento({
                      show: true,
                      fecharVenda: false,
                      pagamentoSelecionado: null
                    })
                  );
                }
              }}
            >
              {showModalLoadingMensagem?.show ? (
                <Skeleton variant="text" width={180} />
              ) : (
                <>
                  <Typography variant="h6">
                    Outros pagamentos{' '}
                    <small>
                      <>{SHOW_SHORTCUTS && `(F10)`}</>
                    </small>
                  </Typography>
                </>
              )}
            </ButtonPagamento>
          </Mui.Grid>

          {/* <Mui.Grid item xs={6} lg={4}>
            <ButtonPagamento
              fullWidth
              variant="outlined"
              style={{
                fontWeight: 500
              }}
              onClick={async () => {
                dispatch(
                  appActions.setShowModalMenuTef({
                    show: true,
                    inicializado: false
                  })
                );
                try {
                  await signalr.invokeInicializaTef();

                  do {
                    await signalr.invokeEfetuaPagamentoTef(new Date().getTime().toString(), 44);
                  } while (showModalMenuTef?.inicializado);
                } catch (error) {
                  console.log('Não foi possivel efetuar o pagamento =>', error?.toString());
                }
              }}
            >
              TESTE TEF
            </ButtonPagamento>
          </Mui.Grid>  */}
        </Mui.Grid>
      </Mui.Box>
    </Mui.Paper>
  );
};

export default CardAtalhoPagamento;
