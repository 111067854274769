import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ArrowBack as IconArrowBack } from '@material-ui/icons';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import api from '~/services/api';
import Searching from '~/components/Searching';
import ListEmpty from '~/components/ListEmpty';
import { SET_FOCUS_QUANTIDADE } from '~/store/modules/pdv/pdv-constants';
import { API_NODE_URL, API_LOCAL_URL } from '~/constants';
import ListHeader from '../../components/ListHeader';
import ListItemProduto from '../../components/ListItemProduto';
import SearchAdornment from '~/components/MultiSearch';

const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      width: 1,
      height: 32,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  })
);

let cancel;
const CancelToken = axios.CancelToken;

const ModalConsultaProduto = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showModalConsultaProdutoRegra } = useSelector(state => state.pdv);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchBy, setSearchBy] = useState('termo');

  const fetcher = async (isOnline) => {
    try {
      setLoading(true);

      if (cancel != undefined) cancel();
      const cancelToken = new CancelToken(function executor(c) {
        cancel = c;
      });

      if (searchInput?.length <= 1) {
        return [];
      }

      setResults([]);

      const { data } = await api.get(
        isOnline
          ? `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda/pdv?produto=${searchInput?.toUpperCase()}&pesquisaPor=${searchBy}`
          : `${API_LOCAL_URL}/estoque/produto/?queryString=${searchInput?.toUpperCase()}`,
        {
          cancelToken
        }
      );

      let produtosExpandidos = [];
      data.forEach(produto => {
        const hasRegra = produto.fat_regradesconto_produto && produto.fat_regradesconto_produto.length > 0;
        const hasTabela = produto.fatTabelavendaProduto && produto.fatTabelavendaProduto.length > 0;

        if (hasRegra && hasTabela) {
          produto.fat_regradesconto_produto.forEach(regra => {
            produtosExpandidos.push({ ...produto, regraAplicada: regra, tabelaAplicada: null, tipo: 'Regra' });
          });
          produto.fatTabelavendaProduto.forEach(tabela => {
            produtosExpandidos.push({ ...produto, regraAplicada: null, tabelaAplicada: tabela, tipo: 'Tabela' });
          });
        } else {
          if (hasRegra) {
            produto.fat_regradesconto_produto.forEach(regra => {
              produtosExpandidos.push({ ...produto, regraAplicada: regra, tipo: 'Regra' });
            });
          }
          
          if (hasTabela) {
            produto.fatTabelavendaProduto.forEach(tabela => {
              produtosExpandidos.push({ ...produto, tabelaAplicada: tabela, tipo: 'Tabela' });
            });
          }
          
          if (!hasRegra && !hasTabela) {
            produtosExpandidos.push({ ...produto, tipo: 'Produto' });
          }
        }
      });

      setResults(produtosExpandidos);

      let newArray = [
        ...data?.map(item => ({
          ...item,
          produtoEstProdutoId: item.id,
          estProdutoGrupo: { ...item.estProdutoGrupo, prefixoProduto: 'EX' },
          itemQuantidade: 1,
          itemValorUnitario: item?.estProdutoImposto[0]?.precoVenda,
          itemEstoque: item?.saldoEstoque
            ? item?.saldoEstoque?.estoqueReal + item?.saldoEstoque?.estoqueReservado * -1
            : 0,
          precoVenda: item?.estProdutoImposto[0]?.precoVenda,
          codigoDesc: item.codigo.split('.')[0],
          codigoNumero: Number(item.codigo.split('.')[1]),
          modalRegra: true,
        }))
      ];

      const ordenaPorDoisValores = (arr, campo1, campo2, reverso = false) => {
        return !reverso
          ? arr.sort(function(a, b) {
              let aCampo1 = a[campo1];
              let bCampo1 = b[campo1];
              let aCampo2 = a[campo2];
              let bCampo2 = b[campo2];

              if (aCampo1 == bCampo1) {
                return aCampo2 < bCampo2 ? -1 : aCampo2 > bCampo2 ? 1 : 0;
              } else {
                return aCampo1 < bCampo1 ? -1 : 1;
              }
            })
          : arr.sort(function(a, b) {
              let aCampo1 = a[campo1];
              let bCampo1 = b[campo1];
              let aCampo2 = a[campo2];
              let bCampo2 = b[campo2];

              if (aCampo1 == bCampo1) {
                return aCampo2 > bCampo2 ? -1 : aCampo2 < bCampo2 ? 1 : 0;
              } else {
                return aCampo1 > bCampo1 ? -1 : 1;
              }
            });
      };
      if (orderBy && sortBy === 'asc') {
        if (orderBy === 'codigo') {
          const dataOrdered = ordenaPorDoisValores(newArray, 'codigoDesc', 'codigoNumero');
          setResults(dataOrdered);
        } else {
          const dataOrdered = newArray.sort((a, b) =>
            a[orderBy] > b[orderBy] ? 1 : b[orderBy] > a[orderBy] ? -1 : 0
          );
          setResults(dataOrdered);
        }
      } else if (orderBy && sortBy === 'desc') {
        if (orderBy === 'codigo') {
          const dataOrdered = ordenaPorDoisValores(newArray, 'codigoDesc', 'codigoNumero', true);
          setResults(dataOrdered);
        } else {
          const dataOrdered = newArray.sort((a, b) =>
            a[orderBy] < b[orderBy] ? 1 : b[orderBy] < a[orderBy] ? -1 : 0
          );
          setResults(dataOrdered);
        }
      } else {
        setResults(newArray);
      }
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConsultaProdutoRegra({ show: false }));
  };

  useEffect(() => {
    fetcher(true);
  }, [searchInput, orderBy, sortBy, searchBy]);

  return (
    <>
      <Dialog
        fullScreen
        open={showModalConsultaProdutoRegra}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
      >
        <>
          <AppBar color="default" position="fixed">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <IconArrowBack />
              </IconButton>

              <Box style={{ minWidth: 180, width: '22%' }}>
                <Typography>Consulta de Produto por Regra e Tabela</Typography>
              </Box>

              <Divider className={classes.divider} />

              <TextField
                variant="outlined"
                autoFocus
                focused
                fullWidth
                placeholder="Pesquise por código, descrição, cód. barra, cód. fabricante, cód. auxiliar"
                value={searchInput}
                onChange={e => {
                  e.preventDefault();
                  const queryText = e.target.value;
                  setSearchInput(queryText);
                }}
                InputProps={{
                  style: { height: 44 },
                  startAdornment: (
                    <SearchAdornment
                      searchBy={searchBy}
                      setSearchBy={setSearchBy}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                    />
                  )
                }}
              />
            </Toolbar>
          </AppBar>

          {loading ? (
            <>
              <Box style={{ marginTop: 64 }}>
                <LinearProgress variant="indeterminate" color="primary" />
              </Box>
              <Searching />
            </>
          ) : (
            <>
              {results?.length > 0 ? (
                <List style={{ marginTop: 64 }}>
                  <ListHeader
                    dados={results}
                    setOrderBy={setOrderBy}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                  />
                  <Divider />
                  {results.map((produto, index) => (
                    <ListItemProduto
                      key={`index-${index}`}
                      data={produto}
                      callback={(origemSelecionado) => {
                        dispatch(
                          pdvActions.selecionarProduto({...produto, origemSelecionado }, () => {
                            dispatch({
                              type: SET_FOCUS_QUANTIDADE,
                              focus: true
                            });
                          })
                        );
                      }}
                    />
                  ))}
                </List>
              ) : (
                <ListEmpty />
              )}
            </>
          )}
        </>
      </Dialog>
    </>
  );
};

export default ModalConsultaProduto;
