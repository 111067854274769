import React from 'react';
import { TextDefault, TitleDefault, DetalhesOrcamento } from '../../../../TelaVenda/styles';
const { formatDate } = require('../../../../../utils/format');

const OrcamentoIdentificationSection = ({ documento, title }) => {
  const situacao = ['Fechado', 'Aberto'];

  return (
    <div style={{ border: '1px solid black', padding: '5px' }}>
      <h2 className="color-black" style={{ textAlign: 'center', fontSize: '23px', paddingTop: '5px' }}>
        {title} - {documento?.serie || documento?.documentoSerie}/{documento?.numero || documento?.documentoNumero}
      </h2>
      <TitleDefault className="color-black">
        <strong>Identificação</strong>
      </TitleDefault>
      <div style={{ fontSize: '13px', display: 'flex', flexWrap: 'wrap' }}>
        <div className="identificacao-orcamento" style={{ width: '60%', fontSize: '13px' }}>
          <TextDefault>
            <span className="color-black" style={{ paddingRight: '38px' }}>
              <strong style={{ fontSize: '13px' }}>Cliente:</strong>
            </span>
            <span style={{ fontSize: '13px' }}>
              {documento?.clienteNome || 'Não informado'}
            </span>
          </TextDefault>

          <TextDefault>
            <span className="color-black" style={{ paddingRight: '15px' }}>
              <strong style={{ fontSize: '13px' }}>CPF/CNPJ:</strong>
            </span>
            <span style={{ fontSize: '13px' }}>
              {documento?.intCliente?.pfCpf} {documento?.intCliente?.pjCnpj}
            </span>
          </TextDefault>

          <TextDefault>
            <span className="color-black" style={{ paddingRight: '26px' }}>
              <strong style={{ fontSize: '13px' }}>Telefone:</strong>
            </span>
            <span style={{ fontSize: '13px' }}>
              {documento?.intCliente?.telefone || 'Não informado'}
            </span>
          </TextDefault>

          <TextDefault>
            <span className="color-black" style={{ paddingRight: '38px' }}>
              <strong style={{ fontSize: '13px' }}>Cidade:</strong>
            </span>
            <span style={{ fontSize: '13px' }}>
              {documento?.intCliente?.enderecoIntCidade?.cidade || 'Não informado'}
            </span>
          </TextDefault>

          <TextDefault>
            <span className="color-black" style={{ paddingRight: '5px' }}>
              <strong style={{ fontSize: '13px' }}>Logradouro:</strong>
            </span>
            <span style={{ fontSize: '13px' }}>
              {documento?.intCliente?.enderecoLogradouro}, {documento?.intCliente?.enderecoLogradouroNumero},  
              {documento?.intCliente?.enderecoIntCidade?.cidade}/{documento?.intCliente?.enderecoIntCidade?.uf}
            </span>
          </TextDefault>
        </div>

        <div className="vendedor-orcamento" style={{ display: 'flex', justifyContent: 'flex-end', width: '40%' }}>
          <div>
            <DetalhesOrcamento>
              <span className="color-black" style={{ paddingRight: '18px' }}>
                <strong style={{ fontSize: '13px' }}>Emissão:</strong>
              </span>
              <span style={{ fontSize: '13px' }}>
                {formatDate(documento?.logCriacao)}
              </span>
            </DetalhesOrcamento>

            <DetalhesOrcamento>
              <span className="color-black" style={{ paddingRight: '17px' }}>
                <strong style={{ fontSize: '13px' }}>Situação:</strong>
              </span>
              <span style={{ fontSize: '13px' }}>{situacao[documento?.situacao]}</span>
            </DetalhesOrcamento>

            <DetalhesOrcamento>
              <span className="color-black" style={{ paddingRight: '10px' }}>
                <strong style={{ fontSize: '13px' }}>Vendedor:</strong>
              </span>
              <span style={{ fontSize: '13px' }}>{documento?.fatVendedor?.nome}</span>
            </DetalhesOrcamento>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrcamentoIdentificationSection;
