import { API_BASE_URL, API_LOCAL_URL, API_NODE_URL } from '~/constants';
import api from '~/services/api';

class OrcamentoService {
  _strategy = null;

  constructor() {
    this._strategy = new OrcamentoOnlineService();
  }

  setStategy(isOffline = false) {
    this._strategy = isOffline ? new OrcamentoOfflineService() : new OrcamentoOnlineService();
  }

  getAll(payload) {
    return this._strategy.getAll(payload);
  }

  syncManual(){
    return this._strategy.syncManual()
  }
}

export class OrcamentoOnlineService {
  async getAll({ skip, top, filter }, opts) {
    const params = {
      $top: top ? top : 100,
      $skip: skip,
      $orderby: 'logCriacao desc',
      $select:
        'id,serie,numero,dataEmissao,clienteNome,valorTotal,situacao,cancelado,logCriacao,logAtualizacao,informacaoComplementar',
      $expand: [
        'fisOperacao($select=descricao)',
        'fatVendedor($select=nome)',
        'fatCondicaopagamento($select=descricao)',
        'finMeiopagamento($select=descricao)',
        'intCliente($select=razaoSocial,telefone,celular,pjCnpj,pfCpf,enderecoCep,enderecoLogradouro,enderecoLogradouroNumero,enderecoBairro,enderecoUf,email)',
        'intCliente($expand=enderecoIntCidade)',
        'fatTabelavenda($select=descricao)',
        'itens($select=produtoCodigo,produtoDescricao,produtoUnidadeComercial,itemValorUnitario,itemValorBruto,itemValorAcrescimoCondicao,itemQuantidade,itemValorDesconto,itemValorAcrescimo,itemValorFrete,itemValorSeguro,itemValorDespesa,itemValorTotal,itemOrdem,itemCancelado)'
      ].join(',')
    };
    let res = await api.get(
      `${API_BASE_URL}/v1/faturamento/orcamento/`,
      {
        params: filter.length > 0 ? { ...params, $filter: [...filter]?.join(` and `) } : { ...params }
      },
      opts
    );

    return res;

  }
}

export class OrcamentoOfflineService {
  getAll(opts) {
    return api.get(`${API_LOCAL_URL}/faturamento/orcamento/situacao/todos`, opts);
  }
  syncManual(payload) {
    return api.post(`${API_LOCAL_URL}/sync/orcamento/`, payload);
  }
}

export default new OrcamentoService();
