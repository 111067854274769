import {
  LIMPAR_VENDA,
  INICIAR_VENDA_REQUEST,
  INICIAR_VENDA_SUCCESS,
  ALTERAR_DOCUMENTO,
  FECHAR_VENDA_SUCCESS,
  FECHAR_VENDA_REQUEST,
  FECHAR_VENDA_ERROR,
  IDENTIFICAR_CLIENTE_REQUEST,
  IDENTIFICAR_CLIENTE_SUCCESS,
  SELECIONAR_PRODUTO_REQUEST,
  SELECIONAR_PRODUTO_SUCCESS,
  SET_SHOWMODAL_ATALHOS_PDV,
  SET_SHOWMODAL_CONSULTA_CLIENTE,
  SET_SHOWMODAL_CONSULTA_PRODUTO,
  SET_SHOWMODAL_CONSULTA_VENDEDOR,
  SET_SHOWMODAL_CONSULTA_PROFISSIONAL,
  SET_SHOWMODAL_CONSULTA_OPERACAO,
  SET_SHOWMODAL_IDENTIFICAR_CLIENTE,
  SET_SHOWMODAL_CONSULTA_PAGAMENTO,
  SET_SHOWMODAL_CONFIRMA_PAGAMENTO,
  SET_SHOWMODAL_CONDICAO_PAGAMENTO_PRODUTO,
  SET_SHOWMODAL_SALDO_ESTOQUE_PRODUTO,
  SET_SHOWMODAL_ENDERECO_CLIENTE,
  SET_SHOWMODAL_EXPEDICAO_MERCADORIA,
  SET_SHOWMODAL_INFORMACAO_COMPLEMENTAR,
  SET_SHOWMODAL_LOADING_MENSAGEM,
  SET_SHOWMODAL_LOADING_SALVAMENTO,
  SET_SHOWMODAL_LIBERACAO_PEDIDO,
  SET_SHOWMODAL_PAGAMENTO_POR_SALDO,
  SET_STATUS,
  SET_LOADING_PRODUTO,
  SET_LOADING_PAGAMENTO,
  SET_LOADING_SALVAMENTO,
  SET_LOADING_IMPRESSAO,
  ATUALIZAR_QUANTIDADE_REQUEST,
  ATUALIZAR_QUANTIDADE_SUCCESS,
  ATUALIZAR_DESCONTO_ITEM,
  ATUALIZAR_DESCONTO_ITEM_PERCENTUAL,
  ATUALIZAR_ACRESCIMO_ITEM,
  ADICIONAR_PRODUTO_REQUEST,
  ADICIONAR_PRODUTO_SUCCESS,
  ATUALIZAR_PRODUTO,
  REMOVER_PRODUTO_REQUEST,
  REMOVER_PRODUTO_SUCCESS,
  CANCELAR_PRODUTO_REQUEST,
  CANCELAR_PRODUTO_SUCCESS,
  SELECIONAR_CLIENTE_REQUEST,
  SELECIONAR_CLIENTE_SUCCESS,
  REMOVE_CLIENTE,
  SELECIONAR_OPERACAO_REQUEST,
  SELECIONAR_OPERACAO_SUCCESS,
  SELECIONAR_VENDEDOR,
  SELECIONAR_PROFISSIONAL,
  SELECIONAR_MEIO_PAGAMENTO_REQUEST,
  SELECIONAR_MEIO_PAGAMENTO_SUCCESS,
  SELECIONAR_MEIO_PAGAMENTO_ENTRADA,
  SELECIONAR_CONDICAO_PAGAMENTO_REQUEST,
  SELECIONAR_CONDICAO_PAGAMENTO_SUCCESS,
  ATUALIZAR_ITEM_PAGAMENTO,
  ATUALIZAR_VALOR_ENTRADA,
  ATUALIZAR_VALOR_RECEBIDO,
  ADICIONAR_PARCELAS_REQUEST,
  ADICIONAR_PARCELAS_SUCCESS,
  ADICIONAR_PAGAMENTO_SUCCESS,
  ADICIONAR_PAGAMENTO_REQUEST,
  SELECIONAR_ENDERECO_CLIENTE,
  UPDATE_LOADING_MENSAGEM,
  INICIAR_PAGAMENTO,
  ATUALIZAR_ITENS,
  ATUALIZAR_PRECO_VENDA_PRODUTO,
  ATUALIZAR_DESCONTO_SUCCESS,
  ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS,
  ATUALIZAR_ACRESCIMO_SUCCESS,
  ATUALIZAR_ACRESCIMO_PERCENTUAL_SUCCESS,
  ALTERAR_TIPO_DOCUMENTO_REQUEST,
  ALTERAR_TIPO_DOCUMENTO_SUCCESS,
  ALTERAR_UNIDADE_COMERCIAL,
  ALTERAR_ARMAZEM,
  ALTERAR_EXPEDICAO_MERCADORIAS,
  EDITAR_ITEM_VENDA,
  SELECIONAR_ENDERECO_ENTREGA,
  SET_LOADING_TRANSMISSAO,
  SET_INFORMACAO_COMPLEMENTAR,
  SET_TRANSFERENCIA_ITEM,
  ATUALIZAR_NUMERO_DOCUMENTO_CARTAO,
  ATUALIZAR_BANDEIRA_CARTAO,
  SET_MULTIPLOS_PAGAMENTOS,
  LIMPAR_PAGAMENTOS,
  SET_SHOWMODAL_LISTA_PAGAMENTOS,
  REMOVER_PAGAMENTOS_REQUEST,
  REMOVER_PAGAMENTOS_SUCCESS,
  SET_SHOWMODAL_IMPORTAR_DOCUMENTO,
  SET_SHOWMODAL_CADASTRO_CHEQUE,
  ADICIONAR_CHEQUE_PARCELA,
  REMOVER_CHEQUE_PARCELA,
  SET_SHOWMODAL_PREVIEW_DANFE,
  SET_SHOWMODAL_CONSULTA_RAPIDA_PRODUTO,
  ATUALIZAR_PARCELA_FECHAMENTO,
  GERAR_DOCUMENTO_OFFLINE,
  ATUALIZAR_PARCELAS,
  IMPORTAR_ORCAMENTO,
  IMPORTAR_PEDIDDO,
  SET_SHOWMODAL_ACRESCIMO,
  SET_SHOWMODAL_DESCONTO,
  EDITAR_QUANTIDADE_ITEM_VENDIDO,
  REMOVER_PROFISSIONAL,
  SELECIONAR_ADD_PRODUTO_REQUEST,
  REMOVER_ULTIMA_PARCELA,
  REMOVER_PARCELA,
  SET_SHOWMODAL_TROCA_USUARIO,
  SET_SHOWMODAL_FRETE,
  ATUALIZAR_FRETE_SUCCESS,
  ATUALIZAR_FRETE_PERCENTUAL_SUCCESS,
  SET_SHOWMODAL_CONSULTA_PRODUTO_REGRA
} from './pdv-constants';

export const iniciarVenda = ({ tipoDocumento, venda = null, limparVenda = false, confirmaLogin = false }) => {
  return {
    type: INICIAR_VENDA_REQUEST,
    venda,
    tipoDocumento,
    limparVenda,
    confirmaLogin
  };
};

export const iniciarVendaSuccess = data => {
  return {
    type: INICIAR_VENDA_SUCCESS,
    data
  };
};

export const limparVenda = () => {
  return {
    type: LIMPAR_VENDA
  };
};

export const fecharVenda = () => {
  return {
    type: FECHAR_VENDA_REQUEST
  };
};

export const fecharVendaSuccess = payload => {
  return {
    type: FECHAR_VENDA_SUCCESS,
    payload
  };
};

export const fecharVendaError = payload => {
  return {
    type: FECHAR_VENDA_ERROR,
    payload
  };
};

export const iniciaPagamento = () => {
  return {
    type: INICIAR_PAGAMENTO
  };
};

export const alterarTipoDocumento = tipoDocumento => {
  return {
    type: ALTERAR_TIPO_DOCUMENTO_REQUEST,
    tipoDocumento
  };
};

export const alterarTipoDocumentoSuccess = tipoDocumento => {
  return {
    type: ALTERAR_TIPO_DOCUMENTO_SUCCESS,
    tipoDocumento
  };
};

export const setStatus = status => {
  return {
    type: SET_STATUS,
    status
  };
};

export const setLoadingSalvamento = loading => {
  return {
    type: SET_LOADING_SALVAMENTO,
    loading
  };
};

export const setLoadingImpressao = loading => {
  return {
    type: SET_LOADING_IMPRESSAO,
    loading
  };
};

export const setLoadingTransmissao = loading => {
  return {
    type: SET_LOADING_TRANSMISSAO,
    loading
  };
};

export const setShowModalAtalhosPdv = payload => {
  return {
    type: SET_SHOWMODAL_ATALHOS_PDV,
    payload
  };
};

export const setShowModalImportarDocumento = payload => {
  return {
    type: SET_SHOWMODAL_IMPORTAR_DOCUMENTO,
    payload
  };
};

export const setShowModalConsultaProduto = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_PRODUTO,
    payload
  };
};

export const setShowModalConsultaProdutoRegra = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_PRODUTO_REGRA,
    payload
  }
}

export const setShowModalConsultaCliente = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_CLIENTE,
    payload
  };
};

export const removeCliente = () => {
  return {
    type: REMOVE_CLIENTE
  };
};

export const setShowModalConsultaVendedor = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_VENDEDOR,
    payload
  };
};
export const setShowModalConsultaProfissional = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_PROFISSIONAL,
    payload
  };
};

export const setShowModalConsultaOperacao = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_OPERACAO,
    payload
  };
};

export const setShowModalConsultaPagamento = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_PAGAMENTO,
    payload
  };
};

export const setShowModalMultiploPagamento = payload => {
  return {
    type: SET_SHOWMODAL_PAGAMENTO_POR_SALDO,
    payload
  };
};

export const setShowModalConfirmaPagamento = payload => {
  return {
    type: SET_SHOWMODAL_CONFIRMA_PAGAMENTO,
    payload
  };
};

export const setShowModalIdentificarCliente = payload => {
  return {
    type: SET_SHOWMODAL_IDENTIFICAR_CLIENTE,
    payload
  };
};

export const setShowModalSaldoEstoqueProduto = payload => {
  return {
    type: SET_SHOWMODAL_SALDO_ESTOQUE_PRODUTO,
    payload
  };
};

export const setShowModalCondicaoPagamentoProduto = payload => {
  return {
    type: SET_SHOWMODAL_CONDICAO_PAGAMENTO_PRODUTO,
    payload
  };
};

export const setShowModalEnderecoCliente = payload => {
  return {
    type: SET_SHOWMODAL_ENDERECO_CLIENTE,
    payload
  };
};

export const setShowModalExpedicaoMercadoria = payload => {
  return {
    type: SET_SHOWMODAL_EXPEDICAO_MERCADORIA,
    payload
  };
};

export const setShowModalInformarComplementar = payload => {
  return {
    type: SET_SHOWMODAL_INFORMACAO_COMPLEMENTAR,
    payload
  };
};

export const setShowModalLoadingSalvamento = payload => {
  return {
    type: SET_SHOWMODAL_LOADING_SALVAMENTO,
    payload
  };
};

export const setShowModalLiberacaoPedido = (payload, callback) => {
  return {
    type: SET_SHOWMODAL_LIBERACAO_PEDIDO,
    payload,
    callback
  };
};

export const selecionarCliente = cliente => {
  return {
    type: SELECIONAR_CLIENTE_REQUEST,
    cliente
  };
};

export const selecionarClienteSuccess = cliente => {
  return {
    type: SELECIONAR_CLIENTE_SUCCESS,
    cliente
  };
};

export const identificarCliente = cliente => {
  return {
    type: IDENTIFICAR_CLIENTE_REQUEST,
    cliente
  };
};

export const identificarClienteSuccess = cliente => {
  return {
    type: IDENTIFICAR_CLIENTE_SUCCESS,
    cliente
  };
};

export const setLoadingProduto = loading => {
  return {
    type: SET_LOADING_PRODUTO,
    loading
  };
};

export const selecionarProduto = (produto, callback) => {
  return {
    type: SELECIONAR_PRODUTO_REQUEST,
    produto,
    callback 
  };
};

export const selecionaAddProduto = (produto, callback, quantidade) => {
  return {
    type: SELECIONAR_ADD_PRODUTO_REQUEST,
    produto,
    callback,
    quantidade
  };
};

export const editarItemVenda = itemVenda => {
  return {
    type: EDITAR_ITEM_VENDA,
    itemVenda
  };
};

export const selecionarProdutoSuccess = itemVenda => {
  return {
    type: SELECIONAR_PRODUTO_SUCCESS,
    itemVenda
  };
};

export const alterarUnidadeComercial = unidade => {
  return {
    type: ALTERAR_UNIDADE_COMERCIAL,
    unidade
  };
};

export const alterarArmazem = armazem => {
  return {
    type: ALTERAR_ARMAZEM,
    armazem
  };
};

export const alterarExpedicaoMercadorias = payload => {
  return {
    type: ALTERAR_EXPEDICAO_MERCADORIAS,
    payload
  };
};

export const selecionarVendedor = vendedor => {
  return {
    type: SELECIONAR_VENDEDOR,
    vendedor
  };
};

export const selecionarProfissional = profissional => {
  return {
    type: SELECIONAR_PROFISSIONAL,
    profissional
  };
};

export const setRemoverProfissional = () => {
  return {
    type: REMOVER_PROFISSIONAL
  };
};

export const selecionarOperacao = operacao => {
  return {
    type: SELECIONAR_OPERACAO_REQUEST,
    operacao
  };
};

export const selecionarOperacaoSuccess = operacao => {
  return {
    type: SELECIONAR_OPERACAO_SUCCESS,
    operacao
  };
};

export const setLoadingPagamento = loading => {
  return {
    type: SET_LOADING_PAGAMENTO,
    loading
  };
};

export const selecionarMeioPagamento = meioPagamento => {
  return {
    type: SELECIONAR_MEIO_PAGAMENTO_REQUEST,
    meioPagamento
  };
};

export const selecionarMeioPagamentoSuccess = meioPagamento => {
  return {
    type: SELECIONAR_MEIO_PAGAMENTO_SUCCESS,
    meioPagamento
  };
};
export const selecionarMeiopagamentoEntrada = meioPagamentoEntrada => {
  return {
    type: SELECIONAR_MEIO_PAGAMENTO_ENTRADA,
    meioPagamentoEntrada
  };
};

export const selecionarCondicaoPagamento = (condicaoPagamento, callback) => {
  return {
    type: SELECIONAR_CONDICAO_PAGAMENTO_REQUEST,
    condicaoPagamento,
    callback
  };
};

export const selecionarCondicaoPagamentoSuccess = condicaoPagamento => {
  return {
    type: SELECIONAR_CONDICAO_PAGAMENTO_SUCCESS,
    condicaoPagamento
  };
};

export const adicionarItem = (itemVenda, callback) => {
  return {
    type: ADICIONAR_PRODUTO_REQUEST,
    itemVenda,
    callback
  };
};

export const adicionarItemSuccess = itemVenda => {
  return {
    type: ADICIONAR_PRODUTO_SUCCESS,
    itemVenda
  };
};

export const atualizarItem = itemVenda => {
  return {
    type: ATUALIZAR_PRODUTO,
    itemVenda
  };
};

export const removerItem = itemVenda => {
  return {
    type: REMOVER_PRODUTO_REQUEST,
    itemVenda
  };
};

export const removerItemSuccess = uuid => {
  return {
    type: REMOVER_PRODUTO_SUCCESS,
    uuid
  };
};

export const atualizarItens = itens => {
  return {
    type: ATUALIZAR_ITENS,
    itens
  };
};

export const cancelarItem = (itemVenda, exigeConfirmacao = true) => {
  return {
    type: CANCELAR_PRODUTO_REQUEST,
    itemVenda,
    exigeConfirmacao
  };
};

export const cancelarItemSuccess = id => {
  return {
    type: CANCELAR_PRODUTO_SUCCESS,
    id
  };
};

export const atualizarQuantidade = (itemVenda, quantidade) => {
  return {
    type: ATUALIZAR_QUANTIDADE_REQUEST,
    itemVenda,
    quantidade
  };
};

export const editarQuantidadeItemVendido = (payload, callback) => {
  return {
    type: EDITAR_QUANTIDADE_ITEM_VENDIDO,
    payload,
    callback
  };
};

export const atualizarQuantidadeSuccess = itemVenda => {
  return {
    type: ATUALIZAR_QUANTIDADE_SUCCESS,
    itemVenda
  };
};

export const atualizarPrecoVendaProduto = (itemVenda, precoVenda) => {
  return {
    type: ATUALIZAR_PRECO_VENDA_PRODUTO,
    itemVenda,
    precoVenda
  };
};

export const atualizarDescontoItem = (itemVenda, desconto) => {
  return {
    type: ATUALIZAR_DESCONTO_ITEM,
    itemVenda,
    desconto
  };
};

export const atualizarDescontoItemPercentual = (itemVenda, percentual) => {
  return {
    type: ATUALIZAR_DESCONTO_ITEM_PERCENTUAL,
    itemVenda,
    percentual
  };
};

export const atualizarAcrescimoItem = (itemVenda, acrescimo) => {
  return {
    type: ATUALIZAR_ACRESCIMO_ITEM,
    itemVenda,
    acrescimo
  };
};

export const atualizarItemPagamento = itemPagamento => {
  return {
    type: ATUALIZAR_ITEM_PAGAMENTO,
    itemPagamento
  };
};

export const atualizarValorEntrada = valorEntrada => {
  return {
    type: ATUALIZAR_VALOR_ENTRADA,
    valorEntrada
  };
};

export const atualizarParcelaFechamento = parcela => {
  return {
    type: ATUALIZAR_PARCELA_FECHAMENTO,
    parcela
  };
};

export const atualizarValorRecebido = valorRecebido => {
  return {
    type: ATUALIZAR_VALOR_RECEBIDO,
    valorRecebido
  };
};

export const adicionarPagamento = () => {
  return {
    type: ADICIONAR_PAGAMENTO_REQUEST
  };
};

export const adicionarPagamentoSuccess = itemPagamento => {
  return {
    type: ADICIONAR_PAGAMENTO_SUCCESS,
    itemPagamento
  };
};

export const selecionarEnderecoEntrega = endereco => {
  return {
    type: SELECIONAR_ENDERECO_ENTREGA,
    endereco
  };
};

export const selecionarEndereco = endereco => {
  return {
    type: SELECIONAR_ENDERECO_CLIENTE,
    endereco
  };
};

export const setShowModalLoadingMensagem = payload => {
  return {
    type: SET_SHOWMODAL_LOADING_MENSAGEM,
    payload
  };
};

export const updateLoadingMensagem = mensagem => {
  return {
    type: UPDATE_LOADING_MENSAGEM,
    mensagem
  };
};

export const atualizarDesconto = (desconto, itensParaDesconto) => {
  return {
    type: ATUALIZAR_DESCONTO_SUCCESS,
    desconto,
    itensParaDesconto
  };
};

export const atualizarDescontoPercentual = (percentual, itensParaDesconto) => {
  return {
    type: ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS,
    percentual,
    itensParaDesconto
  };
};

export const atualizarAcrescimo = acrescimo => {
  return {
    type: ATUALIZAR_ACRESCIMO_SUCCESS,
    acrescimo
  };
};

export const atualizarFrete = frete => {
  return {
    type: ATUALIZAR_FRETE_SUCCESS,
    frete
  };
};

export const atualizarAcrescimoPercentual = percentual => {
  return {
    type: ATUALIZAR_ACRESCIMO_PERCENTUAL_SUCCESS,
    percentual
  };
};

export const atualizarFretePercentual = percentual => {
  return {
    type: ATUALIZAR_FRETE_PERCENTUAL_SUCCESS,
    percentual
  };
};

export const setInformarComplementar = informacaoComplementar => {
  return {
    type: SET_INFORMACAO_COMPLEMENTAR,
    informacaoComplementar
  };
};

export const setTransferenciaItem = payload => {
  return {
    type: SET_TRANSFERENCIA_ITEM,
    payload
  };
};

export const atualizarNumeroDocumentoCartao = (documento, entrada) => {
  return {
    type: ATUALIZAR_NUMERO_DOCUMENTO_CARTAO,
    documento,
    entrada
  };
};

export const atualizarBandeiraCartao = (bandeira, entrada) => {
  return {
    type: ATUALIZAR_BANDEIRA_CARTAO,
    bandeira,
    entrada
  };
};

export const adicionarParcelas = itemPagamento => {
  return {
    type: ADICIONAR_PARCELAS_REQUEST,
    itemPagamento
  };
};

export const removerParcela = pagamentoId => {
  return {
    type: REMOVER_PARCELA,
    pagamentoId
  };
};

export const adicionarParcelasSuccess = itemPagamento => {
  return {
    type: ADICIONAR_PARCELAS_SUCCESS,
    itemPagamento
  };
};

export const setMultiplosPagamentos = multiplosPagamentos => {
  return {
    type: SET_MULTIPLOS_PAGAMENTOS,
    multiplosPagamentos
  };
};

export const limparPagamentos = () => {
  return {
    type: LIMPAR_PAGAMENTOS
  };
};

export const setShowModalListaPagamentos = payload => {
  return {
    type: SET_SHOWMODAL_LISTA_PAGAMENTOS,
    payload
  };
};

export const removerPagamentos = itemsSelecteds => {
  return {
    type: REMOVER_PAGAMENTOS_REQUEST,
    itemsSelecteds
  };
};

export const removerPagamentosSuccess = itemsSelecteds => {
  return {
    type: REMOVER_PAGAMENTOS_SUCCESS,
    itemsSelecteds
  };
};

export const setShowModalCadastroCheque = payload => {
  return {
    type: SET_SHOWMODAL_CADASTRO_CHEQUE,
    payload
  };
};

export const adicionarChequeParcela = (uuid, payload) => {
  return {
    type: ADICIONAR_CHEQUE_PARCELA,
    uuid,
    payload
  };
};

export const removerChequeParcela = uuid => {
  return {
    type: REMOVER_CHEQUE_PARCELA,
    uuid
  };
};

export const setShowModalPreviewDanfe = payload => {
  return {
    type: SET_SHOWMODAL_PREVIEW_DANFE,
    payload
  };
};

export const setShowModalTrocaUsuario = payload => {
  return {
    type: SET_SHOWMODAL_TROCA_USUARIO,
    payload
  };
};

export const setShowModalConsultaRapidaProduto = payload => {
  return {
    type: SET_SHOWMODAL_CONSULTA_RAPIDA_PRODUTO,
    payload
  };
};

export const gerarDocumentoOffline = (payload, imprimirAposGerar) => {
  return {
    type: GERAR_DOCUMENTO_OFFLINE,
    payload,
    imprimirAposGerar
  };
};

export const atualizarParcelas = parcelas => {
  return {
    type: ATUALIZAR_PARCELAS,
    parcelas
  };
};

export const importarOrcamento = (documentos, pathname, callback) => {
  return {
    type: IMPORTAR_ORCAMENTO,
    documentos,
    pathname,
    callback
  };
};

export const importarPedido = (documentos, callback) => {
  return {
    type: IMPORTAR_PEDIDDO,
    documentos,
    callback
  };
};

export const setShowModalDesconto = payload => {
  return {
    type: SET_SHOWMODAL_DESCONTO,
    payload
  };
};

export const setShowModalAcrescimo = payload => {
  return {
    type: SET_SHOWMODAL_ACRESCIMO,
    payload
  };
};

export const setShowModalFrete = payload => {
  return {
    type: SET_SHOWMODAL_FRETE,
    payload
  };
};
