import { API_BASE_URL, API_LOCAL_URL, API_NODE_URL } from '~/constants';
import api from '~/services/api';

class DocumentoSaidaService {
  _strategy = null;

  constructor() {
    this._strategy = new DocumentoSaidaOnlineService();
  }

  setStategy(isOffline = false) {
    this._strategy = isOffline
      ? new DocumentoSaidaOfflineService()
      : new DocumentoSaidaOnlineService();
  }

  getAll(payload) {
    return this._strategy.getAll(payload);
  }

  getId(id) {
    return this._strategy.getId(id);
  }

  getTotalizadores() {
    return this._strategy.getTotalizadores();
  }

  syncManual() {
    return this._strategy.syncManual();
  }
}

export class DocumentoSaidaOnlineService {
  getAll({ status, filtroData, offset, limit }, opts) {
    return api.get(
      `${API_NODE_URL}/faturamento/documento-saida/monitor-nfce/${status}/${filtroData}/${offset}/${limit}`,
      opts
    );
  }

  getAllDocumentos( payload, opts) {
    const params = {
      $top: payload.limit ? payload.limit : 100,
      $skip: payload.offset,
      $orderby: 'logCriacao desc',
      $select: [
        'id',
        'documentoSerie',
        'documentoNumero',
        'documentoDataEmissao',
        'documentoFinalidade',
        'documentoModelo',
        'documentoTransmissao',
        'nfeChave',
        'clienteNome',
        'clienteCpfCnpj',
        'valorTotal',
        'logCriacao',
        'logAtualizacao',
        'informacaoComplementar'
      ].join(','),
      $expand: [
        'fisOperacao($select=descricao)',
        'fatVendedor($select=nome)',
        'fatCondicaopagamento($select=descricao)',
        'intCliente($select=razaoSocial,telefone,celular,pjCnpj,pfCpf,enderecoCep,enderecoLogradouro,enderecoLogradouroNumero,enderecoBairro,enderecoUf,email)',
        'intCliente($expand=enderecoIntCidade)',
        'fatTabelavenda($select=descricao)',
        'itens($select=produtoCodigo,produtoDescricao,produtoUnidadeComercial,itemValorUnitario,itemValorBruto,itemValorAcrescimoCondicao,itemQuantidade,itemValorDesconto,itemValorAcrescimo,itemValorFrete,itemValorSeguro,itemValorDespesa,itemValorTotal,itemOrdem,itemCancelado)',
        'parcelas($expand=finMeiopagamento,fatCartaobandeira)'
      ].join(',')
    };
    return api.get(
      `${API_BASE_URL}/v1/faturamento/documentosaida/`,
      {
        params:
        payload.filter?.length > 0 ? { ...params, $filter: [...payload.filter]?.join(` and `) } : { ...params }
      },
      opts
    );
  }

  getImportacao({ offset, limit, filtros = [], page }, opts) {
    const params = {
      offset,
      limit,
      page
    };
    return api.get(`${API_NODE_URL}/faturamento/documento-saida/importacao/paginado`, {
      params: {
        ...params,
        filtros: JSON.stringify(filtros)
      }
    });
  }

  getId(id) {
    return api.get(`${API_BASE_URL}/v1/faturamento/documentosaida/${id}`);
  }

  getTotalizadores(filtroData) {
    return api.get(
      `${API_NODE_URL}/faturamento/documento-saida/monitor-nfce/totalizadores/${filtroData}`
    );
  }
}

export class DocumentoSaidaOfflineService {
  syncManual(payload) {
    return api.post(`${API_LOCAL_URL}/sync/documentosaida`, payload);
  }
  getAll(opts) {
    return api.get(`${API_LOCAL_URL}/faturamento/documentosaida`, opts);
  }

  getId(id) {}

  getTotalizadores() {}
}

export default new DocumentoSaidaService();
